$primary-color-light: #f6ebd6;

.light {
  .ant-menu {
    background: $primary-color-light;
    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background: transparent;
      }
    }
  }

  .ant-switch {
    background: #bbb2a6;
  }

  .side-navbar-logo {
    height: 32px;
    background-repeat: no-repeat;
    background-image: url('../images/montel-logo-dark.svg');
    margin: 16px;
  }

  .ant-avatar {
    background: $primary-color-light;
  }

  .ant-layout-sider {
    background: $primary-color-light;
  }

  .ant-btn-primary,
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: $primary-color-light;
    border-color: $primary-color-light;
  }

  .ant-table {
    tbody {
      & > tr {
        &.ant-table-row-selected {
          & > td {
            background-color: #aeabab;
          }
        }
        &.ant-table-row {
          &:hover {
            & > td {
              background-color: #aeabab;
            }
          }
        }
      }
    }
  }
}
