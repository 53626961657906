@import "~antd/dist/antd.css";
@import "./DarkTheme.scss";
@import "./LightTheme.scss";

.ant-layout .site-layout{   
  min-height: 100vh;

  .site-layout-background {
    background: #fff;

    .header-buttons-group {
      float: right; 
      margin-right: 40px;
      align-items: center;
      display: flex;
    }

    .warning-version-message {
      display: inline-block;
      position: relative;
      left: 25%;

      .warning-message {
        margin-left: 10px;
        font-size: large;
        font-weight: bold;
      }

      .send-feedback {
        margin-left: 15px;
        vertical-align: middle;
        border-radius: 20px;
        font-weight: 500;
        background-color: #1a252b;
        color: white;
        outline: none;
      }
    }
  }

  .site-content {
    background: #fff;
    font-size: calc(15px + 2vmin);
    display: flex;
    margin: 24px 16px 24px 16px;
    padding: 24px;
  }

  .side-navbar-trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #1890ff;
    }
  }

  .footer {
    text-align: center;
  }
}

