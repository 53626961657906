$primary-color-dark: #23323a;

.dark {
  .side-navbar-logo {
    height: 48px;
    background-repeat: no-repeat;
    background-image: url("../images/montel-alerts-logo.svg");
    margin: 16px;
  }

  .ant-menu {
    background: $primary-color-dark;
    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background: $primary-color-dark;
      }
    }
  }

  .ant-switch {
    background: #1a252b;
  }

  .ant-avatar {
    background: $primary-color-dark;
  }

  .ant-layout-sider {
    background: $primary-color-dark;
  }

  .ant-btn-primary,
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: #1a252b;
    border-color: #1a252b;
  }
  .ant-table {
    tbody {
      & > tr {
        &.ant-table-row-selected {
          & > td {
            background-color: #aeabab;
          }
        }
        &.ant-table-row {
          &:hover {
            & > td {
              background-color: #aeabab;
            }
          }
        }
      }
    }
  }
}
